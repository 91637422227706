import React from 'react'
import Layout from '../../components/layout/layout'
import Header from '../../components/header/header'
import Nav from '../../components/nav/nav'
import Footer from '../../components/footer/footer'
import styles from './studio.module.css'

import studioImage1 from '../../assets/images/studio_01.jpg'
import studioImage2 from '../../assets/images/studio_02.jpg'
import studioImage3 from '../../assets/images/studio_03.jpg'
import studioImage4 from '../../assets/images/studio_04.jpg'
import studioImage5 from '../../assets/images/studio_05.jpg'
import studioImage6 from '../../assets/images/studio_06.jpg'

const images = [
  {
    src: studioImage1,
    alt: 'Liliana finishing part of an installation piece in her Boston studio.'
  },
  {
    src: studioImage2,
    alt: 'Liliana loading her kiln with ceramic pieces.'
  },
  {
    src: studioImage3,
    alt: 'Liliana and Enzo.'
  },
  {
    src: studioImage4,
    alt: 'Close up of ceramic flowers and other works.'
  },
  {
    src: studioImage5,
    alt: 'Liliana and Enzo.'
  },
  {
    src: studioImage6,
    alt: 'Liliana in her Boston studio.'
  }
]

export default () => (
  <Layout>
    <Header />
    <Nav />
    <main style={{ display: 'flex', justifyContent: 'center' }}>
      <div className={styles.imageList}>
        { images.map((image, index) => <img key={index} src={image.src} alt={image.alt} />) }
        <p>
          Photos:&nbsp;
          <a
            href="http://www.bradromano.photography/"
            rel="noopener noreferrer"
            target="_blank"
          >
            Brad Romano
          </a>
        </p>
      </div>
    </main>
    <Footer />
  </Layout>
)
